.btbox[data-v-df556db2] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  margin-top: 15px;
}
.dialog-footer1[data-v-df556db2] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
[data-v-df556db2] .avue-crud .el-table {
  height: calc(100vh - 218px) !important;
  max-height: calc(100vh - 218px) !important;
}
.show_table[data-v-df556db2] {
  position: relative;
  width: 100%;
  overflow: auto;
}
.show_table2[data-v-df556db2] {
  position: relative;
  width: 100%;
  overflow: auto;
}
.paging[data-v-df556db2] {
  float: right;
  margin-top: 10px;
}
